<template>
    <div class="card-course">
        <div class="card-course__header">
            <div class="subtitle">Заказ № {{ order.number }}</div>
            <button class="btn btn-delete" v-if="order.cancel" @click.prevent="isShowModal = true">
                <img src="/img/icons/XCircle.svg" />
                Удалить
            </button>
        </div>
        <div class="card-course__body" v-for="(course, index) in order.courses" :key="course.course_id">
            <template v-if="index === 0">
                <div class="card-course__item">
                    <button
                        v-if="order.download.contract"
                        @click.prevent="saveDoc('contract')"
                        class="btn btn-back"
                    >
                        <svg class="icon icon-clipboard-text ">
                            <use xlink:href="#clipboard-text"></use>
                        </svg>
                        Скачать договор
                    </button>
                    <button
                        v-if="order.download.check"
                        @click.prevent="saveDoc('check')"
                        class="btn btn-back"
                    >
                        <svg class="icon icon-Receipt ">
                            <use xlink:href="#Receipt"></use>
                        </svg>
                        Скачать счет
                    </button>
                    <button v-if="order.download.act" @click.prevent="saveDoc('act')" class="btn btn-back">
                        <svg class="icon icon-arrow-down ">
                            <use xlink:href="#arrow-down"></use>
                        </svg>
                        Скачать акт выполненных услуг
                    </button>
                </div>
                <form class="card-course__pay" v-if="order.pay_online" method="POST" action="https://mait-lk.server.paykeeper.ru/create/" accept-charset="utf-8">
                    <input hidden type="text" name="sum" :value="order.price.replace(/[^\d]/g, '')" />
                    <input hidden type="text" name="service_name" :value="'Оплата Заказа №' + order.number"/>
                    <input v-if="currentUser.email && currentUser.role === 'Заказчик'" hidden type="text" name="client_email" :value="currentUser.email"/>
                    <button type="submit" class="btn btn-red">
                        Оплатить картой
                    </button>
                </form>
            </template>
            <div class="card-course__subtitle">
                Курс {{ course.code }}
            </div>
            <div class="card-course__title text-big strong">
                {{ course.category }}
            </div>
            <div class="card-course__items">
                <div class="card-course__item">
                    <div class="text-gray">Тип обучения:</div>
                    {{ course.type }}
                </div>
                <div class="card-course__item" v-if="course.subcategory">
                    <div class="text-gray">Специализация обучения:</div>
                    {{ course.subcategory }}
                </div>
                <div class="card-course__item" v-if="course.hours">
                    <div class="text-gray">Кол-во часов обучения:</div>
                    {{ course.hours }}
                </div>
                <div class="card-course__item" v-if="course.students_count">
                    <div class="text-gray">Обучающихся:</div>
                    {{ course.students_count }}
                </div>
            </div>
            <Period :dates="course.dates" />
            <div class="card-course__buttons">
                <button class="btn btn-white" @click.prevent="addDays(course.course_id)">
                    Продлить обучение на 7 дней
                </button>
                <a href="/students" class="btn">Данные по обучающимся</a>
            </div>
        </div>

        <div class="card-course__footer">
            <div class="card-course__row">
                <div>Стоимость заказа:</div>
                <div class="text-blue subtitle">{{ order.price }}</div>
            </div>
        </div>
        <BaseModal v-if="isShowModal" @close="closeModal">
            <template v-if="!errors">
                <div class="modal__title subtitle">Вы действительно хотите удалить заказ?</div>
                <div class="modal__btn">
                    <button class="btn btn-red" @click="deleteOrder">Да, удалить</button>
                </div>
            </template>
            <div class="modal__title subtitle" v-else>{{ errors }}</div>
        </BaseModal>
    </div>
</template>

<script>
import Period from "@/pages/personal/components/period"
import BaseModal from "@/components/modals/base-modal";

export default {
    props: {
        order: Object
    },

    components: {
        Period,
        BaseModal
    },

    data() {
        return {
            isShowModal: false,
            errors: null,
        }
    },

    computed: {
        currentUser() {
            return this.$store.state.personal?.user;
        }
    },

    methods: {
        addDays(courseId) {
            this.$store.dispatch("CUSTOMER_ADD_DAYS", {
                order_id: this.order.id,
                course_id: courseId
            }).then(response => this.$store.commit("CUSTOMER_SET_ORDER", response.data))
        },

        saveDoc(type) {
            this.$store.dispatch("CUSTOMER_SAVE_DOCUMENT", {
                type: type,
                orser_id: this.order.id
            })
            .then(response => {
                const disposition = response.headers['content-disposition']
                const ind = disposition.indexOf('filename')
                const length  ='filename="'.length
                const filename = (ind !== -1)
                    ? disposition.substr(ind + length).replace('"', '')
                    :'document'

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
        },
        deleteOrder() {
            this.$store.dispatch("CUSTOMER_DELETE_ORDER", this.order.id)
                .then(() => this.isShowModal = false)
                .catch((error) => this.errors = error.response.data.message)
        },
        closeModal() {
            this.isShowModal = false
            this.errors = null
        },
    }
};
</script>
