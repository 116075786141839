<template>
    <div class="section-grid section-grid--customer">
        <div class="courses-list" v-if="orders.length">
            <CardOrder v-for="order in orders" :key="order.id" :order="order" />
        </div>
        <div class="sticky-wrapper">
            <a href="/application" class="card-custom">
                <span class="card-custom__title subtitle">Подать дополнительную заявку на обучение</span>
                <img src="/img/icons/plus-circle.svg">
            </a>
        </div>
    </div>
</template>

<script>
import store from "@/store/index.js"
import CardOrder from "@/pages/personal/components/card-order"

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("CUSTOMER_GET_ORDERS").then(() => {
            next();
        });
    },

    components: {
        CardOrder
    },

    computed: {
        orders() {
            return this.$store.state.customer.orders
        }
    }
}
</script>
